import { ArrowBack } from "@mui/icons-material";
import {
  AppBar as AppBarMUI,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";

interface IAppBarProps {
  title: string;
}

export const AppBar: React.FC<IAppBarProps> = ({ title }: IAppBarProps) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <AppBarMUI position="static">
      <Toolbar>
        <IconButton
          onClick={handleBack}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {title}
        </Typography>
      </Toolbar>
    </AppBarMUI>
  );
};
