import axios, { AxiosError } from "axios";

const BASE_URL =
  process.env.REACT_APP_ENV === "local"
    ? "http://localhost:4000/api"
    : "https://backoffice.api.sbam.social/api";

const errorHandler = (error: unknown) => {
  console.error(error);
  if ((error as AxiosError)?.status === 401) {
    localStorage.removeItem("auth-token");

    location.reload();
  }
  throw error;
};

type AuthenticatedRequestOptions = {
  method: "get" | "post" | "put" | "delete";
  path: string;
};

export const authenticatedRequest = async <T>({
  method,
  path,
}: AuthenticatedRequestOptions): Promise<T | undefined> => {
  try {
    const response = await axios[method](`${BASE_URL}${path}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth-token"),
      },
    });
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

export type GetCurrentUserRespose = {
  role: string;
  ambassadorCode: string;
  userCode: string;
};
export const getCurrentUser = async () => {
  return await authenticatedRequest<GetCurrentUserRespose>({
    method: "get",
    path: "/user/current",
  });
};
