import { useState } from "react";
import { Button, IconButton, List, Stack, TextField, Box } from "@mui/material";
import { CodeListItem } from "../components/code-list-item";
import { Refresh } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import { AppBar } from "../../../shared/components/app-bar";

export function ReferralCodes() {
  const queryClient = useQueryClient();
  const [input, setInput] = useState("");
  const [codes, setCodes] = useState<string[]>(
    JSON.parse(localStorage.getItem("codes") || "[]")
  );

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["getUsersRegisteredByReferral"],
    });
  };

  const handleSearch = () => {
    setCodes((previousValue) => {
      const newCodes = [...previousValue, input];
      localStorage.setItem("codes", JSON.stringify(newCodes));
      return newCodes;
    });
  };

  const handleDelete = (code: string) => {
    setCodes((previousValue) => {
      const newCodes = previousValue.filter((item) => item !== code);
      localStorage.setItem("codes", JSON.stringify(newCodes));
      return newCodes;
    });
  };

  return (
    <>
      <AppBar title="Referral codes"></AppBar>
      <Box marginTop={2}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <TextField
            label="Codice"
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Cerca
          </Button>

          <Stack direction="column" justifyContent="center">
            <IconButton aria-label="refresh" onClick={handleRefresh}>
              <Refresh />
            </IconButton>
          </Stack>
        </Stack>

        {codes.length > 0 && (
          <div>
            <List>
              {codes.map((code, index) => (
                <CodeListItem
                  key={code + index}
                  code={code}
                  handleDelete={handleDelete}
                />
              ))}
            </List>
          </div>
        )}
      </Box>
    </>
  );
}
