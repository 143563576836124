import React, { useState } from "react";
import { auth } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import {
  indexedDBLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { Box, Button, Stack, TextField } from "@mui/material";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setPersistence(auth, indexedDBLocalPersistence)
        .then(async () => {
          const credentials = await signInWithEmailAndPassword(
            auth,
            email,
            password
          );

          const token = await credentials.user.getIdToken(true);
          localStorage.setItem("auth-token", token);

          navigate("/homepage");
        })
        .catch((error) => {
          console.error("Error with persistence", error);
        });
    } catch (error) {
      console.error("Sign in error", error);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken(true);
      localStorage.setItem("auth-token", token);
      navigate("/homepage");
    } catch (error) {
      console.error("Google sign in error", error);
    }
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSignIn}>
        <Stack spacing={2}>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" type="submit">
            Sign In
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGoogleSignIn}
          >
            Sign In with Google
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

export default Login;
