import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "react-query";
import { getUsersRegisteredByReferral } from "../api";
import { RegistrationCounts } from "../../../shared/types";

interface ICodeListItemProps {
  code: string;
  handleDelete: (code: string) => void;
}

export const CodeListItem: React.FC<ICodeListItemProps> = ({
  code,
  handleDelete,
}: ICodeListItemProps) => {
  const { data, isLoading, isError, error } = useQuery<
    RegistrationCounts | undefined,
    { message: string }
  >(
    ["getUsersRegisteredByReferral", code],
    () => getUsersRegisteredByReferral(code),
    {
      enabled: !!code,
    }
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={48} />;
  }

  if (isError) {
    return (
      <Typography variant="body2" color="error">
        Error: {error?.message}
      </Typography>
    );
  }

  return (
    <ListItem>
      <ListItemText
        primary={code}
        secondary={
          <>
            <Typography component="span" variant="body2">
              Total: {data?.total || 0}
            </Typography>
            <Box component="span" display="flex" gap={2}>
              <Typography variant="caption" color="info.main">
                Male: {data?.genders.male || 0}
              </Typography>
              <Typography variant="caption" color="secondary">
                Female: {data?.genders.female || 0}
              </Typography>
              <Typography variant="caption" color="primary">
                Others: {data?.genders.other || 0}
              </Typography>
              <Typography variant="caption">
                Nulls: {data?.genders.nulls || 0}
              </Typography>
            </Box>
          </>
        }
      />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => handleDelete(code)}
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
