import { Container, Typography } from "@mui/material";
import Navigation from "../components/navigation";

function Homepage() {
  return (
    <div>
      <Navigation />

      <Container>
        <Typography
          variant="h4"
          component="h1"
          align="center"
          style={{ marginTop: "2rem" }}
        >
          Welcome to Sbam Backoffice
        </Typography>
      </Container>
    </div>
  );
}

export default Homepage;
