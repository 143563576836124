import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../firebase";

export const useIsUserLogged = () => {
  const [isLogged, setIsLogged] = useState(
    !!localStorage.getItem("auth-token")
  );

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLogged(true);
      }
    });
  }, []);

  return isLogged;
};
