import { IconButton, Stack, Box, Typography, Skeleton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { useQuery, useQueryClient } from "react-query";
import { AppBar } from "../../../../shared/components/app-bar";
import { useUserContext } from "../../../../providers/UserProvider";
import { getUsersRegisteredByReferral } from "../../../referral-codes/api";
import { RegistrationCounts } from "../../../../shared/types";

interface CodeRowProps {
  code: string;
  codeName: string;
}

function CodeRow({ code, codeName }: CodeRowProps) {
  const { data, isLoading, isError, error } = useQuery<
    RegistrationCounts | undefined,
    { message: string }
  >(
    ["getUsersRegisteredByReferral", code],
    () => getUsersRegisteredByReferral(code),
    {
      enabled: !!code,
    }
  );

  if (isLoading) {
    return <Skeleton variant="rectangular" height={20} width="100%" />;
  }

  if (isError) {
    return (
      <Typography variant="body2" color="error">
        Error: {error?.message}
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        {codeName} code <b>{code}</b>: {data?.total} registrations
      </Typography>
      <Box display="flex" justifyContent="space-around">
        <Typography variant="body2" color="info.main">
          Male: {data?.genders.male}
        </Typography>
        <Typography variant="body2" color="secondary">
          Female: {data?.genders.female}
        </Typography>
        <Typography variant="body2" color="primary">
          Others: {data?.genders.other}
        </Typography>
        <Typography variant="body2">Nulls: {data?.genders.nulls}</Typography>
      </Box>
    </Box>
  );
}

export function MyReferralResults() {
  const queryClient = useQueryClient();
  const { user } = useUserContext();
  const userCode = user?.userCode;
  const ambassadorCode = user?.ambassadorCode;

  if (!user) return null;

  const handleRefresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["getUsersRegisteredByReferral"],
    });
  };

  return (
    <>
      <AppBar title="Referral Results Overview"></AppBar>
      <Box marginTop={2} paddingX={2}>
        <Stack spacing={3} alignItems="center">
          <Box textAlign="center">
            <Typography variant="h5" component="h2" gutterBottom>
              Your Referral Codes Performance
            </Typography>
            <IconButton
              aria-label="refresh"
              onClick={handleRefresh}
              size="large"
            >
              <Refresh />
            </IconButton>
          </Box>
          <Stack alignItems="flex-start" gap={4}>
            {userCode && <CodeRow code={userCode} codeName="User" />}
            {ambassadorCode && (
              <CodeRow code={ambassadorCode} codeName="Referral" />
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
