// src/components/Navigation.js
import { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../firebase";
import { useUserContext } from "../../../providers/UserProvider";

function Navigation() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useUserContext();
  const isUserBoard = !!user && user.role === "BOARD";
  const isUserReferral =
    !!user && (user.role === "BOARD" || !!user.ambassadorCode);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignOut = async () => {
    try {
      setDrawerOpen(!drawerOpen);
      await auth.signOut();
      navigate("/login"); // Use navigate for navigation
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={toggleDrawer}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Sbam backoffice
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          {isUserBoard && (
            <ListItem>
              <ListItemButton onClick={toggleDrawer}>
                <ListItemText
                  primary={<Link to="/referral-codes">Codici referral</Link>}
                />
              </ListItemButton>
            </ListItem>
          )}
          {isUserReferral && (
            <ListItem>
              <ListItemButton onClick={toggleDrawer}>
                <ListItemText
                  primary={
                    <Link to="/my-referral-results">I miei risultati</Link>
                  }
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem>
            <ListItemButton onClick={handleSignOut}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
          {/* Add more menu items as needed */}
        </List>
      </Drawer>
    </>
  );
}

export default Navigation;
