import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { GetCurrentUserRespose, getCurrentUser } from "../shared/api";
import { useIsUserLogged } from "../shared/hooks/useIsUserLogged";

const UserContext = createContext<{ user: GetCurrentUserRespose | undefined }>({
  user: undefined,
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const isLogged = useIsUserLogged();
  const [user, setUser] = useState<GetCurrentUserRespose>();

  const { data } = useQuery<
    GetCurrentUserRespose | undefined,
    { message: string }
  >(["getCurrentUser"], () => getCurrentUser(), {
    enabled: isLogged,
  });

  useEffect(() => {
    setUser(data);
  }, [data]);

  const value = { user };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
