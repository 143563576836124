// src/App.tsx
import {
  Routes,
  Route,
  useNavigate,
  useMatch,
  Navigate,
} from "react-router-dom";
import Login from "./features/login/pages/login";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import Homepage from "./features/homepage/pages/homepage";
import { UserProvider } from "./providers/UserProvider";
import { ReferralCodes } from "./features/referral-codes/pages/referral-codes";
import { MyReferralResults } from "./features/my-referral-results/referral-codes/pages/my-referral-results";

function App() {
  const navigate = useNavigate();

  const isInLoginPage = useMatch("/login");

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        localStorage.setItem("auth-token", token);

        if (isInLoginPage) {
          navigate("/homepage");
        }
      } else {
        localStorage.removeItem("auth-token");

        navigate("/login");
      }
    });
  }, []);

  return (
    <UserProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/homepage" element={<Homepage />} />
        <Route path="/referral-codes" element={<ReferralCodes />} />
        <Route path="/my-referral-results" element={<MyReferralResults />} />
        <Route path="/" element={<Navigate replace to="/homepage" />} />
      </Routes>
    </UserProvider>
  );
}

export default App;
